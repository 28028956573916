.projects__container {
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.projects__content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
}

.projects__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
}

.projects__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
}

.projects__group {
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
}

.projects__data {
    display: flex;
    column-gap: 0.5rem;
}

.projects .bx-badge-check {
    font-size: 1rem;
    color: var(--title-color);
}

.projects__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

.projects__level {
    font-size: var(--tiny-font-size);

}

.project__links {
    display: flex;
    justify-content: center;
    column-gap: 1rem; 
    margin-top: 1rem; 
}

.devpost-button {
    border: .2rem solid var(--title-color-dark);
   background-color:white;
   color: var(--title-color);
}
.devpost-button:hover {
    background-color: var(--title-color-dark);
    color: white;
}

.projects__img {
    border-radius: 1rem;
}

@media  screen and (max-width: 992px) {
   .projects__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
   }
}

@media  screen and (max-width: 576px) {
    .projects__container {
        grid-template-columns: 1fr;
    }

    .projects__content {
        padding: 1.5rem;
    }
}

@media  screen and (max-width: 350px) {
    .projects__box {
        column-gap: 1.25rem;
    }

    .projects__name {
        font-size: var(--small-font-size);
    }
}